import React from "react";
import Link from "@/utils/link";

const Button = ({
    children,
    onClick,
    variant = "contained",
    color = "primary",
    size = "md",
    disabled = false,
    type = "button",
    className = "",
    href = "",
}) => {
    let cn = "block focus:ring-2 rounded text-center";

    if (variant === "contained") {
        if (disabled) {
            cn += " text-gray-500 bg-gray-200 ";
        } else if (color === "white") {
            cn +=
                " text-gray-900 bg-gray-100 hover:bg-gray-200 focus:ring-4 focus:ring-gray-50";
        } else if (color === "gray") {
            cn +=
                " text-white bg-gray-400 hover:bg-gray-500 focus:ring-4 focus:ring-gray-300";
        } else if (color === "blue") {
            cn += " text-white bg-blue-700 hover:bg-blue-800 focus:ring-blue-300";
        } else if (color === "primary") {
            cn +=
                " text-white bg-primary-500 hover:bg-primary-med focus:ring-4 focus:ring-primary-300";
        } else {
            cn +=
                " text-white bg-primary-500 hover:bg-primary-400 focus:ring-4 focus:ring-primary-300";
        }
    } else if (variant === "outlined") {
        if (color === "blue") {
            if (disabled) {
                cn += " text-gray-500 border-2 border-gray-400 ";
            } else {
                cn += " text-white bg-blue-700 hover:bg-blue-800 focus:ring-blue-300";
            }
        } else {
            if (disabled) {
                cn += " text-gray-500 border-2 border-gray-400";
            } else {
                cn +=
                    " text-primary-500 border-2 border-primary-500 hover:bg-primary-500 hover:text-white focus:ring-4 focus:ring-primary-300";
            }
        }
    }

    if (disabled) {
        cn += " cursor-not-allowed";
    }

    if (size === "xs") {
        cn += " text-xs px-2 py-1.5";
    } else if (size === "sm") {
        cn += " text-sm px-3 py-2";
    } else if (size === "md") {
        cn += " text-sm px-4 py-2.5 font-semibold";
    } else if (size === "lg") {
        cn += " text-base px-5 py-3 font-semibold";
    } else if (size === "xl") {
        cn += " text-lg px-6 py-3.5 font-bold";
    }

    if (className) {
        cn += " " + className;
    }

    if (href) {
        return (
            <Link href={href}>
                <a className={cn} disabled={disabled}>
                    {children}
                </a>
            </Link>
        );
    }

    return (
        <button type={type} onClick={onClick} className={cn} disabled={disabled}>
            {children}
        </button>
    );
};

export default Button;
