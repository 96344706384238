import Link from "next/link";

const MyLink = ({ href, children, disabled = false }) => {
    if (disabled) {
        return <>{children}</>;
    }

    return (
        <Link legacyBehavior href={href}>
            {children}
        </Link>
    );
};

export default MyLink;
