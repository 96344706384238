export const getMostRecentSearchCity = () => {
    const result = window.localStorage.getItem("most-recent-search-city");

    if (result) {
        return JSON.parse(result);
    } else {
        return null;
    }
};

export const setMostRecentSearchCity = (city) => {
    window.localStorage.setItem("most-recent-search-city", JSON.stringify(city));
};

export const getFavourites = () => {
    const favourites = window.localStorage.getItem("favourites");
    if (favourites) {
        return JSON.parse(favourites);
    } else {
        return {};
    }
};

export const setFavourites = (favourites = {}) => {
    window.localStorage.setItem("favourites", JSON.stringify(favourites));
};

export const getExOut = () => {
    const exOut = window.localStorage.getItem("exOut");
    if (exOut) {
        return JSON.parse(exOut);
    } else {
        return {};
    }
};

export const setExOut = (exOut = {}) => {
    window.localStorage.setItem("exOut", JSON.stringify(exOut));
};
