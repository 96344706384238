import { doc, setDoc, collection, addDoc, getDoc, updateDoc } from "firebase/firestore";

import firebase from "./firebase";

const { db } = firebase;

export const createUser = async (uid, data) => {
    const ref = doc(db, "users", uid);
    const result = await setDoc(ref, { uid, ...data }, { merge: true });

    return result;
};

export const readDocument = async (collection, id) => {
    const ref = doc(db, collection, id);
    const docSnap = await getDoc(ref);

    if (docSnap.exists()) {
        return docSnap.data();
    } else {
        return null;
    }
};

export const updateDocument = async (collection, id, data) => {
    const ref = doc(db, collection, id);
    await updateDoc(ref, data);
};
