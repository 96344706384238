import Head from "next/head";
import { useRouter } from "next/router";

const Meta = ({ title, keywords, description, jsonLd, ogTitle, ogImage, ogType }) => {
    const router = useRouter();
    const p = router.asPath.slice(1);
    let canonicalURL = `https://purpledoorfinders.com/${p}`.split("?")[0];

    if (canonicalURL.startsWith("https://purpledoorfinders.com/community-portal")) {
        canonicalURL = `https://portal.purpledoorfinders.com/${p}`.split("?")[0];
    }

    return (
        <Head>
            <meta charSet="utf-8" />
            <meta name="viewport" content="width=device-width, initial-scale=1" />
            <meta name="keywords" content={keywords} />
            <meta name="description" content={description} />

            {ogTitle ? <meta property="og:title" content={ogTitle} /> : null}
            {ogImage ? <meta property="og:image" content={ogImage} /> : null}
            {description ? <meta property="og:description" content={description} /> : null}
            {canonicalURL ? <meta property="og:url" content={canonicalURL} /> : null}
            {ogType ? <meta property="og:type" content={ogType} /> : null}

            <link rel="icon" href="/favicon.ico" />
            <link rel="canonical" href={canonicalURL} />
            <title>{title}</title>
            {jsonLd ? (
                <script
                    type="application/ld+json"
                    dangerouslySetInnerHTML={{ __html: JSON.stringify(jsonLd) }}
                />
            ) : null}
        </Head>
    );
};

Meta.defaultProps = {
    title: "Purple Door Finders",
    keywords: "",
    description: "Purple Door Finders",
};

export default Meta;
