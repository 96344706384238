import * as types from "./actionTypes";

export const toggleFavourite = (nameId) => {
    return { type: types.TOGGLE_FAVOURITE, nameId };
};

export const addToFavourites = (nameId) => {
    return { type: types.ADD_TO_FAVOURITES, nameId };
};

export const removeFromFavourites = (nameId) => {
    return { type: types.REMOVE_FROM_FAVOURITES, nameId };
};

export const setFavourites = (favourites) => {
    return { type: types.SET_FAVOURITES, favourites };
};

export const toggleExOut = (nameId) => {
    return { type: types.TOGGLE_EX_OUT, nameId };
};

export const addToExOut = (nameId) => {
    return { type: types.ADD_TO_EX_OUT, nameId };
};

export const removeFromExOut = (nameId) => {
    return { type: types.REMOVE_FROM_EX_OUT, nameId };
};

export const setExOut = (exOut) => {
    return { type: types.SET_EX_OUT, exOut };
};
