import * as types from "../actions/actionTypes";
import initialState from "./initialState";
import { setFavourites, setExOut } from "../components/local/localStorage";

export default function localReducer(state = initialState.local, action) {
    switch (action.type) {
        case types.TOGGLE_FAVOURITE: {
            const favourites = {
                ...state.favourites,
                [action.nameId]: state.favourites[action.nameId] ? false : true,
            };

            setFavourites(favourites);
            return { ...state, favourites };
        }

        case types.ADD_TO_FAVOURITES: {
            const favourites = { ...state.favourites, [action.nameId]: true };

            setFavourites(favourites);
            return { ...state, favourites };
        }

        case types.REMOVE_FROM_FAVOURITES: {
            const favourites = { ...state.favourites, [action.nameId]: false };

            setFavourites(favourites);
            return { ...state, favourites };
        }

        case types.SET_FAVOURITES:
            return { ...state, favourites: action.favourites };

        case types.ADD_TO_EX_OUT: {
            const exOut = { ...state.exOut, [action.nameId]: true };

            setExOut(exOut);
            return { ...state, exOut };
        }

        case types.REMOVE_FROM_EX_OUT: {
            const exOut = { ...state.exOut, [action.nameId]: false };

            setExOut(exOut);
            return { ...state, exOut };
        }

        case types.SET_EX_OUT:
            return { ...state, exOut: action.exOut };

        case types.TOGGLE_EX_OUT: {
            const exOut = {
                ...state.exOut,
                [action.nameId]: state.exOut[action.nameId] ? false : true,
            };

            setExOut(exOut);
            return { ...state, exOut };
        }

        default:
            return state;
    }
}
