import React, { useState, useEffect, useRef } from "react";

import Meta from "./Meta";
import { useLocalStorage, useWindowSize } from "../../utils/hooks";
import { LayoutContext } from "./LayoutContext";
import Button from "@/ui/Button";

const ZOOM_MD = 1.25;
const ZOOM_LG = 1.8;

const Layout = ({ children, meta }) => {
    const ref = useRef();
    //const { width, height } = useWindowSize();
    const width = 1920;
    const [zoom, setZoom] = useState(1);
    const [font] = useLocalStorage("font-body", "font-bodySans");
    const [accepted, setAccepted] = useLocalStorage("disclaimerAccepted", false);
    const [localAccepted, setLocalAccepted] = useState(true);

    useEffect(() => {
        if (ref.current) {
            if (font === "font-body") {
                ref.current.className = "h-screen -mt-16 antialiased font-body";
            } else {
                ref.current.className = "h-screen -mt-16 antialiased font-bodySans";
            }
        }
    }, [font]);

    useEffect(() => {
        setLocalAccepted(accepted);
    }, [accepted]);

    useEffect(() => {
        if (width < 3500) {
            // full HD (1920 x 1080p) or less
            if (zoom > 1) {
                setZoom(1);
            }
        } else if (width >= 3500) {
            // 4k 3840 x 2160p
            if (zoom !== ZOOM_LG) {
                setZoom(ZOOM_LG);
            }
        }
    }, [zoom, width]);

    /*useEffect(() => {
        if (width < 2200) {
            // full HD (1920 x 1080p) or less
            if (zoom > 1) {
                setZoom(1);
            }
        } else if (width >= 2200 && width < 3500) {
            // (2560 - 3440) x 1440p
            if (zoom !== ZOOM_MD && height > 1080) {
                setZoom(ZOOM_MD);
            }
        } else if (width >= 3500) {
            // 4k 3840 x 2160p
            if (zoom !== ZOOM_LG) {
                setZoom(ZOOM_LG);
            }
        }
    }, [zoom, width]);*/

    console.log("width", width, "zoom", zoom);

    return (
        <>
            <Meta {...meta} />
            <div
                ref={ref}
                className="h-screen -mt-16 antialiased font-body"
                style={{ zoom }}
            >
                <main className="mt-16">
                    <LayoutContext.Provider value={{ zoom }}>
                        <div className="relative">
                            <div>{children}</div>

                            {localAccepted ? null : (
                                <div className="py-4 px-4 fixed top-[calc(100vh-200px)] sm:top-[calc(100vh-120px)] right-0 left-0 w-full overflow-y-scroll sm:overflow-y-auto h-full sm:h-[500px] bg-primary-700 text-primary-50">
                                    <h3 className="text-lg sm:text-xl">
                                        Disclaimer: no endorsements, affiliations, brokerage
                                        or professional advice.
                                    </h3>

                                    {/*<p className="mt-4 max-w-5xl text-sm">
                                        Purpledoor provides only information without
                                        endorsing or recommending any community. we do not
                                        facilitate placements, conduct medical evaluations,
                                        or intervene in selection or admittance. it's the
                                        potential resident's responsibility to assess their
                                        fit for a community. we neither broker, sell, nor
                                        lease space.
                                    </p>

                                    <p className="mt-4 max-w-5xl text-sm">
                                        All content on our sites or services is for
                                        informational purposes only, not to replace
                                        professional advice. we disclaim liability for
                                        actions taken based on this information. always
                                        consult professionals for medical, legal, financial,
                                        or other advice. use of our services implies your
                                        understanding of this release of liability.
                                    </p>*/}

                                    {/*<p className="">
                                        notice: all content on our sites or services is for
                                        informational purposes only, not to replace
                                        professional advice.
                                    </p>*/}

                                    <div className="mt-4 mb-4 flex space-x-12 items-start sm:items-baseline">
                                        <p className="text-sm">
                                            For more complete information, please see our
                                            privacy policy and terms of use.
                                        </p>

                                        <Button
                                            color="white"
                                            onClick={() => setAccepted(true)}
                                        >
                                            Accept
                                        </Button>
                                    </div>
                                </div>
                            )}
                        </div>
                    </LayoutContext.Provider>
                </main>
            </div>
            <div id="body-for-popper"></div>
        </>
    );
};

export default Layout;
