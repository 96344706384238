import { useMemo } from "react";
import { createStore } from "redux";
import { rootReducer } from "../reducers";
import initialState from "../reducers/initialState";

/**
 * https://github.com/vercel/next.js/tree/canary/examples/with-redux
 * This example shows how to integrate Redux in Next.js.
 * Usually splitting your app state into pages feels natural but sometimes
 *  you'll want to have global state for your app.
 * This is an example on how you can use Redux that also works with Next.js's universal rendering approach.
 *
 * In the first example we are going to display a digital clock that updates every second.
 * The first render is happening in the server and then the browser will take over.
 * To illustrate this, the server rendered clock will have a different background color (black) than the client one (grey).
 *
 * To illustrate SSG and SSR, go to /ssg and /ssr,
 * those pages are using Next.js data fetching methods to get the date in the server
 * and return it as props to the page, and then the browser will hydrate the store and continue updating the date.
 *
 * The trick here for supporting universal Redux is to separate the cases for the client and the server.
 * When we are on the server we want to create a new store every time, otherwise different users data will be mixed up. If we are in the client we want to use always the same store. That's what we accomplish on store.js.
 *
 * All components have access to the Redux store using useSelector, useDispatch or connect from react-redux.
 *
 * On the server side every request initializes a new store,
 * because otherwise different user data can be mixed up. On the client side the same store is used, even between page changes.
 */

let store;

function initStore(preloadedState = initialState) {
    return createStore(rootReducer, preloadedState);
}

export const initializeStore = (preloadedState) => {
    let _store = store ?? initStore(preloadedState);

    // After navigating to a page with an initial Redux state, merge that state
    // with the current state in the store, and create a new store
    if (preloadedState && store) {
        _store = initStore({ ...store.getState(), ...preloadedState });

        // Reset the current store
        store = undefined;
    }

    // For SSG and SSR always create a new store
    if (typeof window === "undefined") {
        return _store;
    }

    // Create the store once in the client
    if (!store) {
        store = _store;
    }

    return _store;
};

export function useStore(initialState) {
    // const store = useMemo(() => {
    //     initializeStore(initialState);
    // }, [initialState]);

    const store = initializeStore(initialState);

    return store;
}
