import React, { useState, useEffect, useRef } from "react";

const isClient = typeof window === "object";

/**
 * like useState with local storage
 * From: https://usehooks.com/useLocalStorage/
 * @param {*} key
 * @param {*} initialValue
 */
export const useLocalStorage = (key, initialValue, enableLocalStorage = true) => {
    const keyRef = useRef(key);

    // State to store our value
    // Pass initial state function to useState so logic is only executed once
    const [storedValue, setStoredValue] = useState(() => {
        if (enableLocalStorage && isClient) {
            try {
                // Get from local storage by key
                const item = window.localStorage.getItem(keyRef.current);

                // Parse stored json or if none return initialValue
                return item ? JSON.parse(item) : initialValue;
            } catch (error) {
                // If error also return initialValue
                console.log(error);
                return initialValue;
            }
        } else {
            return initialValue;
        }
    });

    // Return a wrapped version of useState's setter function that ...
    // ... persists the new value to localStorage.
    const setValue = (value) => {
        try {
            // Allow value to be a function so we have same API as useState
            const valueToStore = value instanceof Function ? value(storedValue) : value;

            // Save state
            setStoredValue(valueToStore);

            // Save to local storage
            if (enableLocalStorage) {
                window.localStorage.setItem(keyRef.current, JSON.stringify(valueToStore));
            }
        } catch (error) {
            // A more advanced implementation would handle the error case
            console.log(error);
        }
    };

    const updateKey = (newKey) => {
        keyRef.current = newKey;

        // Get from local storage by key
        const item = window.localStorage.getItem(keyRef.current);

        // Parse stored json or if none return initialValue
        const parsedItem = item ? JSON.parse(item) : initialValue;

        setStoredValue(parsedItem);
    };

    return [storedValue, setValue, updateKey];
};

/**
 * get the window size
 * From: https://usehooks.com/useWindowSize/
 */
export const useWindowSize = () => {
    function getSize() {
        return {
            width: isClient ? window.innerWidth : undefined,
            height: isClient ? window.innerHeight : undefined,
        };
    }

    const [windowSize, setWindowSize] = useState(getSize);

    useEffect(() => {
        if (!isClient) {
            return;
        }

        function handleResize() {
            setWindowSize(getSize());
        }

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []); // Empty array ensures that effect is only run on mount and unmount

    return windowSize;
};

/**
 * Used internally by useUpdateEffect
 */
const useIsMounted = function useIsMounted() {
    const isMounted = React.useRef(false);

    React.useEffect(function setIsMounted() {
        isMounted.current = true;

        return function cleanupSetIsMounted() {
            isMounted.current = false;
        };
    }, []);

    return isMounted;
};

/**
 * Update only after the component is mounted
 * @param {*} effect
 * @param {*} dependencies
 */
export const useUpdateEffect = function useUpdateEffect(effect, dependencies) {
    const isMounted = useIsMounted();
    const isInitialMount = React.useRef(true);

    React.useEffect(() => {
        let effectCleanupFunc = function noop() {};

        if (isInitialMount.current) {
            isInitialMount.current = false;
        } else {
            effectCleanupFunc = effect() || effectCleanupFunc;
        }
        return () => {
            effectCleanupFunc();
            if (!isMounted.current) {
                isInitialMount.current = true;
            }
        };
    }, dependencies); // eslint-disable-line react-hooks/exhaustive-deps
};

/**
 * https://usehooks.com/useHover/
 *
 * Usage:
 * const [hoverRef, isHovered] = useHover();
 * return <div ref={hoverRef}>
 *
 * @returns
 */
function useHover() {
    const [value, setValue] = useState(false);
    const ref = useRef(null);
    const handleMouseOver = () => setValue(true);
    const handleMouseOut = () => setValue(false);
    useEffect(
        () => {
            const node = ref.current;
            if (node) {
                node.addEventListener("mouseover", handleMouseOver);
                node.addEventListener("mouseout", handleMouseOut);
                return () => {
                    node.removeEventListener("mouseover", handleMouseOver);
                    node.removeEventListener("mouseout", handleMouseOut);
                };
            }
        },
        [ref.current] // Recall only if ref changes
    );
    return [ref, value];
}

/**
 * https://michalmuszynski.com/blog/react-scroll-handler-hook/
 */
const isWindowAvailable = typeof window !== "undefined";

const getPosition = () => (isWindowAvailable ? window.pageYOffset : undefined);

export const useWindowScrollPosition = () => {
    const [scrollPosition, setScrollPosition] = useState(getPosition());

    useEffect(() => {
        if (isWindowAvailable) {
            const handleScroll = () => {
                setScrollPosition(getPosition());
            };

            window.addEventListener("scroll", handleScroll);

            return () => window.removeEventListener("scroll", handleScroll);
        }
    }, []);

    return scrollPosition;
};

/**
 * https://blog.riley.gg/is-visible-custom-react-hook
 * @param {*} param0
 * @returns
 */
export const useIsVisible = (ref, parentRef, parentHieght) => {
    const [visible, setVisible] = useState(null);

    useEffect(() => {
        const onScroll = () => {
            console.log("scrollTop", parentRef.current.scrollTop);
            isVisible(parentRef.current.scrollTop);
        };

        if (parentRef && parentRef.current) {
            parentRef.current.addEventListener("scroll", onScroll);
        }

        isVisible(parentRef && parentRef.current ? parentRef.current.scrollTop : 0);

        return () => {
            if (parentRef && parentRef.current) {
                parentRef.current.removeEventListener("scroll", onScroll);
            }
        };
    }, [ref, parentRef, parentHieght]);

    // check element rect top
    const isVisible = (scroll) => {
        const top = ref && ref.current ? ref.current.getBoundingClientRect().top : 0;
        const topParent =
            parentRef && parentRef.current
                ? parentRef.current.getBoundingClientRect().top
                : 0;

        if (top - topParent >= scroll && top - topParent <= scroll + parentHieght) {
            setVisible(true);
        } else {
            setVisible(false);
        }
    };

    // debounce function execution
    function debounce(func, delay) {
        let timeout = null;
        return function () {
            window.clearTimeout(timeout);
            timeout = window.setTimeout(function () {
                func();
            }, delay);
        };
    }

    return visible;
};

// https://github.com/tailwindlabs/headlessui/blob/main/packages/%40headlessui-react/src/hooks/use-latest-value.ts
export function useLatestValue(value) {
    let cache = useRef(value);

    useEffect(() => {
        cache.current = value;
    }, [value]);

    return cache;
}

// https://github.com/tailwindlabs/headlessui/blob/main/packages/%40headlessui-react/src/hooks/use-event-listener.ts
export function useEventListener(element, type, listener, options) {
    let listenerRef = useLatestValue(listener);

    useEffect(() => {
        element = element ?? window;

        function handler(event) {
            listenerRef.current(event);
        }

        element.addEventListener(type, handler, options);
        return () => element.removeEventListener(type, handler, options);
    }, [element, type, options]);
}
