import { initializeApp, getApp, getApps } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import "firebase/firestore";

const config = {
    apiKey: process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
    authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
    storageBucket: process.env.NEXT_PUBLIC_STORAGE_BUCKET,
    messagingSenderId: process.env.NEXT_PUBLIC_MESSAGING_SENDER_ID,
    appId: process.env.NEXT_PUBLIC_FIREBASE_APP_ID,
    measurementId: process.env.NEXT_PUBLIC_MEASUREMENT_ID,
};

// config for purple-door-test (eranc6)
const config2 = {
    apiKey: "AIzaSyBuo1FybE_jO1xqV8dFsb67VwXUvhuoJGk",
    authDomain: "purple-door-test.firebaseapp.com",
    projectId: "purple-door-test",
    storageBucket: "purple-door-test.appspot.com",
    messagingSenderId: "970236216988",
    appId: "1:970236216988:web:e8ec60d7e2cff1ba8f0f59",
};

let app;

if (!getApps().length) {
    app = initializeApp(config);
} else {
    app = getApp();
}

const auth = getAuth(app);
const db = getFirestore();

export default { auth, db };
