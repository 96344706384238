// components/FacebookPixel.js
import { useEffect } from "react";

const FacebookPixel = () => {
    useEffect(() => {
        // Check if the Pixel script is already loaded to prevent duplicates
        if (!window.fbq) {
            // Insert Facebook Pixel script using native DOM manipulation
            !(function (f, b, e, v, n, t, s) {
                if (f.fbq) return;
                n = f.fbq = function () {
                    n.callMethod
                        ? n.callMethod.apply(n, arguments)
                        : n.queue.push(arguments);
                };
                if (!f._fbq) f._fbq = n;
                n.push = n;
                n.loaded = !0;
                n.version = "2.0";
                n.queue = [];
                t = b.createElement(e);
                t.async = !0;
                t.src = v;
                s = b.getElementsByTagName(e)[0];
                s.parentNode.insertBefore(t, s);
            })(
                window,
                document,
                "script",
                "https://connect.facebook.net/en_US/fbevents.js"
            );

            // Initialize the Pixel with your specific ID
            fbq("init", "534808775746407");
            fbq("track", "PageView");
        }
    }, []);

    // // Return the noscript tag for browsers that do not support JavaScript
    // return (
    //     <noscript>
    //         <img
    //             height="1"
    //             width="1"
    //             style={{ display: "none" }}
    //             src="https://www.facebook.com/tr?id=534808775746407&ev=PageView&noscript=1"
    //             alt=""
    //         />
    //     </noscript>
    // );

    return null;
};

export default FacebookPixel;
